<template>
  <div class="out-back-demo">
      <div v-for="(item, index) in CustomList" :key="index">
        <Custom @customMusic="customMusic" :CustomList="item" type="canceled"></Custom>
      </div>
	  <CustomPopup ref="customPopup" :customMusic="customMusicData"></CustomPopup>
  </div>
</template>
    
<script>
import Custom from "@/components/content/Custom.vue"
import CustomPopup from "@/components/popup/CustomPopup.vue"
export default {
  props: {},
  data() {
    return {
		customMusicData: {},
      CustomList: [{
        music_title: '英雄联盟-黑暗时刻',
        end_time: '2021-08-22',
        release_time: '2021-08-21',
        custom_code: '350967',
        img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwx2.sinaimg.cn%2Fmw690%2F006C7yiwly1h7u0pmoa3sj30e80e8jrx.jpg&refer=http%3A%2F%2Fwx2.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671432854&t=6ab6ae01e27281bc4f859ce23ccdb0fc',
      },
      {
        music_title: '英雄联盟-黑暗时刻',
       end_time: '2021-08-22',
       release_time: '2021-08-21',
        custom_code: '350967',
        img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwx2.sinaimg.cn%2Fmw690%2F006C7yiwly1h7u0pmoa3sj30e80e8jrx.jpg&refer=http%3A%2F%2Fwx2.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671432854&t=6ab6ae01e27281bc4f859ce23ccdb0fc',


      }]

    }
  },
  components: { Custom,CustomPopup },
  computed: {},
  created() {
  },
  mounted() {
  },
  watch: {},
  methods: {
	customMusic(data){
		// console.log(data,'customMusic');
		this.customMusicData = data;
		this.$refs.customPopup.open();
	}
  }
}
</script>
    
<style scoped lang='less'>
.out-back-demo {
  width: 100%;
  height: 850px;
}
</style>
    